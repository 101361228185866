<template>
  <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
    :paginate-elements-by-height="500" filename="hee hee" :pdf-quality="2" :manual-pagination="false" pdf-format="a10"
    pdf-orientation="landscape" pdf-content-width="5.8cm" @progress="onProgress($event)"
    @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)"
    @beforeDownload="beforeDownload($event)" ref="html2Pdf">
    <section slot="pdf-content">

      <!-- Header -->
      <pre>
------------------------------------
           Auriel Nail Art
------------------------------------
Date        : {{ currentDateTime }}
Invoice #   : {{ invoiceNo }}
Therapist   : {{ therapistDefault }}
------------------------------------
</pre>
      <pre v-for="item in invoiceItems" :key="item.id">
{{ formatInvoiceItem(item) }}
</pre>

      <!-- Subtotal -->
      <pre>
------------------------------------
Subtotal               Rp.{{ calculateSubtotal().toLocaleString() }}
{{ paddedDiscount }}        Rp.{{ calculateDiscount().toLocaleString() }}
</pre>
      <!-- Total and Footer -->
      <pre>
------------------------------------
Total                  Rp.{{ calculateTotal() }}
------------------------------------
             Thank you!
         Please come again.
</pre>

    </section>
  </vue-html2pdf>
</template>

<script>
import { mapState } from 'vuex';
import VueHtml2pdf from 'vue-html2pdf';

export default {
  name: 'PrintPage',
  props: {
    invoiceNo: {
      type: String,
      required: true
    },
    discountPercentage: {
      type: Number,
      required: false
    },
  },
  data() {
    return {
      digit: 7,
    }
  },
  computed: {
    ...mapState(['invoiceItems', 'therapists'])
    , therapistDefault() {
      return this.therapist !== null ? this.therapist : '--';
    },
    paddedDiscount() {
      const discountString = `Discount (${this.discountPercentage}%)`;
      const paddedDiscount = discountString.padEnd(15, ' ');
      return paddedDiscount;
    },
    currentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    },
    therapist() {
      const uniqueTherapistSet = new Set();
      this.invoiceItems.forEach(item => {
        const therapistValue = item.product.pegawai;
        const therapist = this.therapists.find(therapist => therapist.id === therapistValue);

        if (therapist) {
          uniqueTherapistSet.add(therapist.nama);
        }
      });
      const therapistValues = Array.from(uniqueTherapistSet).join(', ');

      if (uniqueTherapistSet.size > 0) {
        return therapistValues;
      } else {
        return "--";
      }
    }
  },
  methods: {
    itemSubtotal(item) {
      return (item.product.harga * item.quantity).toLocaleString()
    },
    itemHarga(item) {
      return item.product.harga.toLocaleString().toString().padEnd(this.digit, ' ');
    },
    formatInvoiceItem(item) {
      return `${item.product.nama} X${item.quantity}\n@${this.itemHarga(item)}               Rp ${this.itemSubtotal(item)}`;
    },
    calculateSubtotal() {
      return this.invoiceItems.reduce((total, item) => {
        return total + item.product.harga * item.quantity;
      }, 0);
    },
    calculateDiscount() {
      return this.calculateSubtotal() * this.discountPercentage / 100
    },
    calculateTotal() {
      return (this.calculateSubtotal() - this.calculateDiscount()).toLocaleString();
    }
  },
  mounted() {
    window.addEventListener("afterprint", () => {
      console.log("After print");
    }),
      window.onafterprint = () => {
        console.log("s")
      }
  },
  components: {
    VueHtml2pdf
  }
};
</script>

<style scoped>
pre {
  font-family: Arial, sans-serif;
}
</style>
