import Vue from "vue";
import Vuex from "vuex";
import { getPegawai } from "../core/backend-api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    invoiceItems: [],
    therapists: [],
  },
  mutations: {
    addToInvoice(state, product) {
      const existingItem = state.invoiceItems.find(
        (item) => item.product.id === product.id
      );

      if (existingItem) {
        existingItem.quantity++;
      } else {
        const newProduct = { ...product }; //so old data is not returned
        state.invoiceItems.push({ product: newProduct, quantity: 1 });
      }
    },
    clearInvoiceItems(state) {
      state.invoiceItems = [];
    },
    setTherapists(state, therapists) {
      state.therapists = therapists;
    },
  },
  actions: {
    addProductToInvoice({ commit }, product) {
      commit("addToInvoice", product);
    },
    async fetchTherapists({ commit }) {
      const pegawaiData = await getPegawai();
      commit("setTherapists", pegawaiData.data);
    },
  },
  modules: {},
});
