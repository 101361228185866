<template>
  <v-app :style="{ background: $vuetify.theme.themes.dark.background }">
    <SideBar />
    <SideBarRight />
    <v-container>

      <v-toolbar flat>
        <v-toolbar-title class="mt-n1">
          <h1>BEATRIX NAIL ART</h1>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-text-field v-model="searchText" label="Search by product name..." class="mt-5" color="brown" filled
          append-icon="mdi-magnify" dense solo flat background-color="grey lighten-4"></v-text-field>

      </v-toolbar>

      <v-toolbar color="rgba(0,0,0,0)" flat>
        <v-toolbar-title class="mt-n3">Product Category</v-toolbar-title>
      </v-toolbar>

      <v-item-group mandatory class="mt-n1">
        <v-container>
          <v-row class="space">

            <v-col cols="12" md="2">
              <v-item v-slot="{ active }">
                <v-card :color="active ? '#F6EFEF' : 'white'" :class="active ? 'borderme' : 'borderout'"
                  class="d-flex align-center rounded-lg mx-1" dark height="140" @click="filterProductsByCategory('ALL')"
                  flat>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-list-item three-line class="text-center">
                        <v-list-item-content>
                          <v-img src="c1.png" height="80" width="80" contain></v-img>
                          <v-list-item-subtitle :class="active ? 'brown--text' : 'black--text'" class="caption mt-4">
                            ALL
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card>
              </v-item>
            </v-col>

            <v-col cols="12" md="2" v-for="cat in categorys" :key="cat.id">
              <v-item>
                <!-- <v-item v-slot="{ active }"> -->

                <v-card :color="active ? '#F6EFEF' : 'white'" :class="active ? 'borderme' : 'borderout'"
                  class="d-flex align-center rounded-lg mx-1" dark height="140" @click="filterProducts(cat.id)" flat>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-list-item three-line class="text-center ">
                        <v-list-item-content>
                          <div align="center" justify="center">
                            <v-img :src="cat.foto" height="80" width="80" contain></v-img>
                          </div>
                          <v-list-item-subtitle :class="active ? 'brown--text' : 'black--text text-uppercase'"
                            class=" caption mt-4">{{ cat.nama }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card>

              </v-item>
            </v-col>
          </v-row>

        </v-container>
      </v-item-group>
      <v-toolbar flat>
        <v-toolbar-title>Products</v-toolbar-title><v-spacer></v-spacer><span color="grey">{{ productCount }} Items
          Found</span>
      </v-toolbar>

      <v-row>
        <v-col cols="12" sm="6" v-for="product in products" :key="product.id">
          <v-card class="mx-2 my-1" elevation="5">
            <v-list-item>
              <v-list-item-avatar rounded size="100" color="grey lighten-4">
                <v-img v-if="product.id_kategori == 1" src="service.png"></v-img>
                <v-img v-else-if="product.id_kategori == 2" src="item.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  <strong>{{ product.nama }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle class="mt-1">{{ product.deskripsi }}</v-list-item-subtitle>
                <div class="mt-5 price">
                  <strong>Rp. {{ product.harga }}</strong>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn block dark color="brown" class="text-uppercase" @click="addProductToInvoice(product.id)">Tambah
                Item</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-app>
</template>

<script>
import SideBar from '../components/SideBar'
import SideBarRight from '../components/SideBarRight'
import { getBarangs, getKategoris } from '../core/backend-api';
import store from '../store';

export default {
  name: 'Home',
  data() {
    return {
      products: [],
      categorys: [],
      searchText: "",
      selectedCategory: "ALL",
    };
  },
  computed: {
    productCount() {
      return this.products.length;
    }
  },
  created() {
    this.loadProducts();
  },
  methods: {
    async filterProducts(category) {
      this.products = [];
      const response = await getBarangs();
      const allProducts = response.data;

      if (category) {
        this.selectedCategory = category;
      }

      if (this.selectedCategory === "ALL") {
        this.products = allProducts.filter(product => product.nama.toLowerCase().includes(this.searchText.toLowerCase()));
      } else {
        this.products = allProducts.filter(product =>
          product.id_kategori === this.selectedCategory &&
          product.nama.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }
    },
    async loadProducts() {
      try {
        const response = await getBarangs();
        this.products = response.data;
        const kategori = await getKategoris();
        this.categorys = kategori.data;

      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    async addProductToInvoice(id) {
      const product = this.products.find(product => product.id === id);
      console.log(product);
      store.dispatch('addProductToInvoice', product);
    }
  },
  watch: {
    searchText() {
      this.filterProducts(this.selectedCategory);
    },
  },
  components: {
    SideBar,
    SideBarRight
  },
}
</script>

<style>
.v-card.borderme {
  border: 2px solid #ac6953 !important;
}

.v-card.borderout {
  border: 1px solid #BCAAA4 !important;
}

.price {
  font-size: 1.2rem;
}
</style>
