<template>
    <v-navigation-drawer app color="white" right width="290">
        <v-list subheader two-line class="mt-1">
            <v-list-item>
                <v-list-item-avatar rounded>
                    <v-img src="person.png"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle>Customer</v-list-item-subtitle>
                    <v-list-item-title>Cashier</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <strong class="ml-4">Invoice</strong>

        <div v-if="invoiceItems.length > 0">
            <v-list subheader two-line class="mt-1" v-for="item in invoiceItems" :key="item.id">
                <v-list-item>
                    <v-list-item-avatar rounded color="grey lighten-4">
                        <v-img src="2.png"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-2">{{ item.product.nama }}</v-list-item-title>
                        <v-list-item-subtitle class="text-bold">X{{ item.quantity }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="caption">Rp. {{ item.product.harga }}</v-list-item-action>
                </v-list-item><v-select class="mx-4 mt-n4 h-50 mb-n2 inline select-box" v-model="item.product.pegawai"
                    :items="therapists" item-text="nama" item-value="id" label="Therapist"></v-select>
                <v-divider class="mx-4"></v-divider>
            </v-list>
        </div>

        <div v-else>
            <p class="mx-4 mt-4">--No item in invoice--</p>
        </div>

        <v-toolbar color="rgba(0,0,0,0)" flat>
            <strong>Subtotal</strong>
            <v-spacer></v-spacer>
            <strong>Rp. {{ calculateSubtotal() }}</strong>
        </v-toolbar>

        <v-toolbar color="rgba(0,0,0,0)" flat class="mt-n6">
            <span class="mr-2">Discount(%)</span>
            <v-spacer></v-spacer>
            <v-text-field v-model="discountPercentage" type="number" @input="updateDiscount()"
                class="mx-16 mr-auto text-right" min="0" max="100" :style="{ width: '0px' }"></v-text-field>
        </v-toolbar>

        <v-toolbar color="rgba(0,0,0,0)" flat class="mt-n8">
            <span class="mr-2">Discount</span>
            <v-spacer></v-spacer>
            <strong>Rp. {{ discount }} </strong>
        </v-toolbar>

        <v-divider class="mx-4"></v-divider>

        <v-toolbar color="rgba(0,0,0,0)" flat>
            <strong>Total</strong>
            <v-spacer></v-spacer>
            <strong>Rp. {{ calculateTotal() }}</strong>
        </v-toolbar>

        <strong class="ml-4">Payment Method</strong>
        <v-item-group mandatory class="mt-n1">
            <v-container>
                <v-row justify="center">
                    <v-col v-for="payment in payments" :key="payment.id" cols="12" md="4">
                        <v-item v-slot="{ active, toggle }">
                            <v-card color="#F6EFEF" :class="active ? 'borderme' : ''" class="d-flex align-center rounded-lg"
                                dark height="70" @click="handlePaymentClick(payment.id); toggle()" flat>
                                <v-list-item three-line class="text-center mt-1">
                                    <v-list-item-content>
                                        <div>
                                            <v-icon :color="active ? '#704232' : 'black'">fas fa-qrcode</v-icon>
                                        </div>
                                        <v-list-item-subtitle :class="active ? 'brown--text' : 'black--text'"
                                            class="mt-n2 caption">{{ payment.nama }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-item>
                    </v-col>
                </v-row>
            </v-container>
        </v-item-group>
        <div class="mx-3 mt-2">
            <v-btn color="#704232" block dark class="widthoutupercase" @click="printInvoice">Print dan Simpan</v-btn>
            <PrintPage ref="printPageRef" style="display: none;" :discountPercentage="discountPercentage"
                :invoiceNo="invoiceNo" />
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import PrintPage from './PrintInvoice.vue';
import { getPembayaran, addInvoice, getInvoiceNumber } from '../core/backend-api';

export default {
    data() {
        return {
            discountPercentage: 0,
            discount: 0,
            payments: [],
            payment: 1, //default cash,
            invoiceNo: ''
        };
    },
    computed: {
        ...mapState(['invoiceItems', 'therapists']),
    },
    created() {
        this.loadPembayaran();
    },
    methods: {
        calculateSubtotal() {
            return this.invoiceItems.reduce((total, item) => {
                return total + (item.product.harga * item.quantity);
            }, 0);
        },
        calculateDiscount() {
            const subtotal = this.calculateSubtotal();
            if (this.discountPercentage != null) {
                this.discount = (subtotal * this.discountPercentage / 100)
            } else {
                this.discount = 0;
            }
            return this.discount
        },
        calculateTotal() {
            const subtotal = this.calculateSubtotal();
            const disc = parseFloat(this.calculateDiscount());
            return (subtotal - disc)
        },
        updateDiscount() {
            this.calculateTotal();
        },
        async printInvoice() {

            if (this.invoiceItems.length > 0) {
                await this.getInvoiceNo();

                const beforePrint = function () {
                    console.log('Functionality to run before printing.');
                };

                window.onbeforeprint = beforePrint;

                const printPageComponent = this.$refs.printPageRef;
                const printWindow = window.open('', '', 'width=600,height=600');
                printWindow.document.open();
                printWindow.document.write(`
                    <html>
                    <head>
                        <title>Print Page</title>
                    </head>
                    <body>
                    ${printPageComponent.$el.innerHTML}
                    </body>
                    </html>
                `);
                printWindow.document.close();

                // Check if the print preview is canceled
                let isPrintCanceled = false;

                const mediaQueryList = window.matchMedia('print');
                const handleMediaChange = (e) => {
                    if (!e.matches) {
                        console.log('Print preview was canceled.');
                        isPrintCanceled = true;
                        mediaQueryList.removeEventListener('change', handleMediaChange);
                    }
                };

                mediaQueryList.addEventListener('change', handleMediaChange);

                printWindow.print();

                await new Promise((resolve) => setTimeout(resolve, 1000));
                printWindow.close();

                if (isPrintCanceled) {
                    console.log('Print preview was canceled.');
                    return; // Stop the process
                } else {
                    this.addInvoice();
                }
            } else {
                alert("tidak ada barang untuk di print")
            }
        },
        async getInvoiceNo() {
            const currentDate = new Date();
            var date = currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate()
            const response = await getInvoiceNumber(date);
            const lastid = response.data.toString().padStart(4, '0');
            this.invoiceNo = 'INV-' + lastid;
            console.log(this.invoiceNo)
            console.log(this.discountPercentage)
        },
        async addInvoice() {
            const invoiceData = {
                id_pembayaran: this.payment, //metode pembayaran
                tanggal: this.getCurrentDateTimeFormatted(),
                listbarang: this.invoiceItems,
                discount: this.discountPercentage
            };
            const response = await addInvoice(invoiceData);
            if (response.status == 200) {
                this.$store.commit('clearInvoiceItems');
            }
            else {
                alert("Server sedang error, silahkan coba lagi");
            }
        },
        async loadPembayaran() {
            try {
                const response = await getPembayaran();
                this.payments = response.data;
            } catch (error) {
                console.error('Error fetching pembayaran:', error);
            }
        },
        handlePaymentClick(paymentId) {
            this.payment = paymentId;
        },
        getCurrentDateTimeFormatted() {
            const currentDateTime = new Date();

            const year = currentDateTime.getFullYear();
            const month = String(currentDateTime.getMonth() + 1).padStart(2, '0'); // Adding 1 to month and formatting to have two digits
            const day = String(currentDateTime.getDate()).padStart(2, '0');
            const hours = String(currentDateTime.getHours()).padStart(2, '0');
            const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
            const seconds = String(currentDateTime.getSeconds()).padStart(2, '0');
            const milliseconds = String(currentDateTime.getMilliseconds()).padStart(6, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
        }
    },
    components: {
        PrintPage
    },
};
</script>

<style scoped>
.v-text-field.mx-0.ml-auto {
    width: 10px;
}

.v-card.borderme {
    border: 2px solid #ac6953 !important;
}

.col-12 {
    padding: 5px !important;
}

.v-btn.widthoutupercase {
    text-transform: none !important;
}


.v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
    max-height: 56px;
}

.v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
    min-height: auto !important;
}
</style>