import axios from 'axios';

// Define your API base URL
const BASE_URL = 'https://aurielbackend.eldridgerexroy.com/api';

// Create functions for API calls
export const getPembayaran = () => {
  return axios.get(`${BASE_URL}/pembayaran`);
};
export const getBarangs = () => {
  return axios.get(`${BASE_URL}/barang`);
};
export const getKategoris = () => {
  return axios.get(`${BASE_URL}/kategori`);
};
export const getPegawai = () => {
  return axios.get(`${BASE_URL}/getuserbyrole/pegawai`);
};
export const getInvoiceNumber = (tanggal) => {
 return axios.get(`${BASE_URL}/getinvoicenumber/${tanggal}`)
};
export const addInvoice = (invoiceData) => {
  return axios.post(`${BASE_URL}/invoice`, invoiceData);
};